import * as React from 'react'
import classNames from 'classnames'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {useWidgetProps} from '../../hooks/widget-props'
import {classes} from './empty-state.st.css'

export const EmptyState = () => {
  const {t} = useWidgetProps()
  const {isMobile} = useEnvironment()

  return <div className={classNames(classes.root, {[classes.mobile]: isMobile})}>{t('scheduleEmptyStateText')}</div>
}
